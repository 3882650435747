/* eslint-disable max-lines-per-function */
import { CloudSyncOutlined, LoadingOutlined } from "@ant-design/icons";
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import { FieldOwnerType, FieldSetType } from "_graphql-types-frontend/graphql";
import { Button, Popover, Tooltip, notification } from "antd";
import Collapse from "antd/lib/collapse";
import Dropdown from "antd/lib/dropdown";
import Menu from "antd/lib/menu";
import Tag from "antd/lib/tag";
import classNames from "classnames";
import { Set as ImmutableSet } from "immutable";
import _, { compact, isString } from "lodash";
import omitDeep from "omit-deep-lodash";
import {
  ChangeEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import ReactDiffViewer from "react-diff-viewer";
import {
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  useFormContext,
  useFormState,
} from "react-hook-form";
import { asFullDateTime } from "../../utils/date";
import { useCurrentUser } from "../../utils/hooks";
import Modal from "../Modal";
import { TemplateContext } from "./Context";
import ImportFieldsModal from "./ImportFields";
import { KEYS_TO_OMMIT_ON_INPUT } from "./ImportFields/helpers";
import VersionSelector from "./VersionSelector";
import { Field, FieldInput } from "./types";
const { Panel } = Collapse;

function FormChanges({
  changes,
}: {
  changes: { new: NonNullable<Field>; prev: NonNullable<Field> }[];
}) {
  const { readFieldFromCache } = useContext(TemplateContext);

  const [showChanges, setShowChanges] = useState(false);
  const [hasBeenOpenedSinceNewChanges, setHasBeenOpenedSinceLastChanges] =
    useState(false);
  const [_, setCurrentChanges] = useState(changes);

  const [previousChanges, setPreviousChanges] = useState(changes);

  useEffect(() => {
    if (previousChanges.length !== changes.length) {
      setHasBeenOpenedSinceLastChanges(false);
      setPreviousChanges(changes);
    }
  }, [changes]);

  const changesComponents = changes.map(
    ({ new: newField, prev: prevField }) => {
      const templateKey = `${newField.ownerType}.${newField.fieldKey}`;
      const { field, metadata } = readFieldFromCache(templateKey);

      const header = (
        <div className="d-flex justify-between">
          <div className="d-flex mb-16">
            <div className="flex-grow">
              <span className="in-secondary-blue">
                {newField.modifyUser} updated
              </span>{" "}
              {(metadata && metadata.displayName) || JSON.stringify(field)}
              <p className="cms-publish-modal__time">
                {asFullDateTime(newField.modifyDate)}
              </p>
            </div>
          </div>
        </div>
      );

      return (
        <Collapse>
          <Panel key={templateKey} header={header}>
            <DiffDraftField draftField={newField} publishedField={prevField} />
          </Panel>
        </Collapse>
      );
    }
  );

  const content = (
    <div data-testid="form-changes-list">{changesComponents}</div>
  );

  return (
    <>
      <Tooltip
        title={
          hasBeenOpenedSinceNewChanges
            ? "Synced changes"
            : "Newly synced changes"
        }
      >
        <Button
          type="link"
          onClick={() => {
            setShowChanges(!showChanges);
            setHasBeenOpenedSinceLastChanges(true);
          }}
        >
          <CloudSyncOutlined
            style={{
              color: hasBeenOpenedSinceNewChanges ? undefined : "orange",
            }}
          />
        </Button>
      </Tooltip>

      <Modal
        title={
          hasBeenOpenedSinceNewChanges
            ? "Synced changes"
            : "Newly synced changes"
        }
        visible={showChanges}
        onCancel={() => {
          setShowChanges(false);
        }}
      >
        {content}
      </Modal>
    </>
  );
}

function FormErrors({
  errors,
}: {
  errors: FieldErrorsImpl<DeepRequired<FieldValues>>;
}) {
  const dig = (
    object: any,
    fieldPath: string | null
  ): { message: string; fieldPath: string }[] => {
    if (object.message && object.type) {
      return [
        {
          fieldPath: fieldPath as string,
          message: object.message as string,
        },
      ];
    }

    return Object.entries(object)
      .map(([key, value]: [string, any]) => {
        if (!value) return [];
        let id;
        if (Array.isArray(object)) {
          return dig(
            value,
            (fieldPath && [fieldPath, `[${key}]`].join("")) || `[${key}]`
          );
        }
        return dig(value, (fieldPath && [fieldPath, key].join(".")) || key);
      })
      .flat();
  };

  const leaves = dig(errors, null);

  const errorsComponents = leaves.map(
    ({ message, fieldPath }: { message: string; fieldPath: string }) => {
      const inputElement = document.getElementsByName(fieldPath)[0];
      return (
        <div key={fieldPath}>
          {inputElement ? (
            <Button
              type="link"
              onClick={() => {
                document.getElementsByName(fieldPath)[0].scrollIntoView({
                  block: "center",
                  behavior: "smooth",
                });
              }}
            >
              {fieldPath}:
            </Button>
          ) : (
            <h4>{fieldPath + ":"}</h4>
          )}

          {message}
        </div>
      );
    }
  );

  const content = <div data-testid="form-errors-list">{errorsComponents}</div>;
  return (
    <Popover content={content} title={"Form Errors"} placement="topRight">
      <ExclamationCircleOutlined
        style={{ color: "red" }}
        data-testid="form-errors"
      />
    </Popover>
  );
}

function DiffDraftField({
  draftField,
  publishedField,
}: {
  draftField: {
    value?: any;
    documentIds?: number[] | null;
    notApplicable?: boolean | null;
  } | null;
  publishedField: {
    value?: any;
    documentIds?: number[] | null;
    notApplicable?: boolean | null;
  } | null;
}) {
  return (
    <>
      {!_.isEqual(draftField?.notApplicable, publishedField?.notApplicable) && (
        <>
          Not Applicable Changes:
          <ReactDiffViewer
            oldValue={JSON.stringify(publishedField?.notApplicable, null, 2)}
            newValue={JSON.stringify(draftField?.notApplicable, null, 2)}
            splitView={true}
            hideLineNumbers={true}
          />
        </>
      )}
      {!_.isEqual(draftField?.documentIds, publishedField?.documentIds) && (
        <>
          Document Changes:
          <ReactDiffViewer
            oldValue={JSON.stringify(publishedField?.documentIds, null, 2)}
            newValue={JSON.stringify(draftField?.documentIds, null, 2)}
            splitView={true}
            hideLineNumbers={true}
          />
        </>
      )}
      {!_.isEqual(draftField?.value, publishedField?.value) && (
        <>
          Value Changes:
          <ReactDiffViewer
            oldValue={JSON.stringify(publishedField?.value, null, 2)}
            newValue={JSON.stringify(draftField?.value, null, 2)}
            splitView={true}
            hideLineNumbers={true}
          />
        </>
      )}
      {_.isEqual(draftField?.notApplicable, publishedField?.notApplicable) &&
        _.isEqual(draftField?.documentIds, publishedField?.documentIds) &&
        _.isEqual(draftField?.value, publishedField?.value) && (
          <div>No Draft Changes</div>
        )}
    </>
  );
}

function ChangedFieldDiff({
  draftField,
  toggleField,
  isSelected,
}: {
  draftField: NonNullable<Field>;
  toggleField: (arg0: NonNullable<Field>) => ChangeEventHandler<any>;
  isSelected: boolean;
}) {
  const { readFieldFromCache } = useContext(TemplateContext);

  const templateKey = `${draftField.ownerType}.${draftField.fieldKey}`;
  const { field, metadata } = readFieldFromCache(templateKey);

  const header = (
    <div className="d-flex justify-between">
      <div className="d-flex mb-16">
        <div className="flex-grow">
          <span className="in-secondary-blue">
            {draftField.modifyUser} updated
          </span>{" "}
          {(metadata && metadata.displayName) || JSON.stringify(field)}
          <p className="cms-publish-modal__time">
            {asFullDateTime(draftField.modifyDate)}
          </p>
        </div>
      </div>
      <input
        type="checkbox"
        className="main-checkbox__input"
        checked={isSelected}
        onChange={toggleField(draftField)}
      />
      <label className="main-checkbox__label" onClick={toggleField(draftField)}>
        Publish
      </label>
    </div>
  );

  return (
    <Collapse>
      <Panel key={templateKey} header={header}>
        <DiffDraftField draftField={draftField} publishedField={field} />
      </Panel>
    </Collapse>
  );
}

export default function PublishTemplateHeading({
  formServerUpdates,
  onPublishToolBarClick,
}: {
  formServerUpdates: { new: NonNullable<Field>; prev: NonNullable<Field> }[];
  onPublishToolBarClick?: () =>
    | Promise<void | { error: boolean }>
    | (void | { error: boolean });
}) {
  const {
    name,
    ownerType,
    draftData,
    publishedData,
    saveFields,
    deleteFields,
    simplified,
    onPublish,
    onIsCompleted,
    saving,
  } = useContext(TemplateContext);

  const { setError } = useFormContext();

  const { isDirty, errors } = useFormState();

  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showImportFieldsModal, setShowImportFieldsModal] = useState(false);

  const currentUser = useCurrentUser();

  const draftFields = useMemo(() => {
    if (!draftData) return [];

    return Object.values(draftData)
      .map(entityFields =>
        compact(
          Object.values(entityFields).map(field => {
            if (isString(field)) return null;

            return field;
          })
        )
      )
      .flat();
  }, [draftData]);

  const { email, writePublishState, writeDraftState } = useCurrentUser();

  const initialSelected = useMemo(
    () => draftFields.filter(({ modifyUser }) => modifyUser === email),
    [draftFields]
  );

  const [selectedFields, setSelectedFields] = useState(
    ImmutableSet(initialSelected)
  );

  useEffect(() => {
    setSelectedFields(ImmutableSet(initialSelected));
  }, [initialSelected]);

  const toggleField = useCallback(
    (field: NonNullable<Field>) => () => {
      if (selectedFields.has(field)) {
        setSelectedFields(selectedFields.delete(field));
      } else {
        setSelectedFields(selectedFields.add(field));
      }
    },
    [selectedFields, setSelectedFields]
  );

  const [isPublishing, setIsPublishing] = useState(false);

  const publishFields = useCallback(
    (selectedFields: any) => {
      setIsPublishing(true);
      setShowPublishModal(false);
      const fieldsToSave = selectedFields.reduce(
        (
          accu: { [key: string]: { [key: string]: FieldInput } },
          field: Field
        ) => {
          if (field) {
            accu[field.ownerType][field.fieldKey] = omitDeep(
              {
                ...field,
                state: null,
              },
              KEYS_TO_OMMIT_ON_INPUT
            );
          }

          return accu;
        },
        { investment: {}, firm: {}, company: {} }
      );

      const fieldsToDelete = selectedFields.reduce(
        (
          accu: { [key: string]: { [key: string]: FieldInput } },
          field: Field
        ) => {
          if (field) {
            accu[field.ownerType][field.fieldKey] = omitDeep(
              {
                ...field,
                state: currentUser.writeDraftState,
              },
              KEYS_TO_OMMIT_ON_INPUT
            );
          }

          return accu;
        },
        { investment: {}, firm: {}, company: {} }
      );

      notification.info({
        key: "publish_fields_saving",
        message: `Saving fields...`,
      });

      saveFields(fieldsToSave, writePublishState)
        .catch(e => {
          notification.error({
            key: "publish_fields_error",
            message: "Publishing fields failed.",
            description: e.message,
          });
          setError("Publish Error", {
            type: "custom",
            message: `Failed to Publish Fields \n ${e.message}`,
          });
          throw e;
        })
        .then(() => {
          notification.success({
            key: "publish_fields_success",
            message: `Successfully published ${selectedFields.size} fields.`,
          });
          onIsCompleted &&
            Object.values({
              ...publishedData?.investment,
              ...publishedData?.company,
              ...fieldsToSave.company,
              ...fieldsToSave.investment,
              ...fieldsToSave.firm,
            }).every(field => field) &&
            onIsCompleted();

          onPublish && onPublish();

          return deleteFields(fieldsToDelete, writeDraftState);
        })
        .finally(() => {
          setIsPublishing(false);
        });
    },
    [saveFields, onPublish, onIsCompleted, publishedData]
  );

  const selectAllFields = () => {
    if (draftFields.length === selectedFields.size) {
      return setSelectedFields(selectedFields.filter(() => false));
    }
    setSelectedFields(ImmutableSet(draftFields));
  };

  const onPublishToolBarClickAction = useCallback(async () => {
    try {
      const result = onPublishToolBarClick && (await onPublishToolBarClick());
      if (result?.error) {
        return;
      }
      if (!simplified) return setShowPublishModal(true);
      setSelectedFields(ImmutableSet(draftFields));
      publishFields(selectedFields);
    } catch (e: any) {
      console.error(e.message);
    }
  }, [
    publishFields,
    selectedFields,
    onPublishToolBarClick,
    setSelectedFields,
    draftFields,
    simplified,
    setShowPublishModal,
  ]);

  return (
    <>
      <div className="subheader-wrap">
        <div className="subheader">
          {Object.values(errors).length > 0 && <FormErrors errors={errors} />}
          <div>
            <div className="cms-updates__counter">
              <span
                data-cy="draft-field-count"
                className="cms-updates__counter-number"
              >
                {draftFields.length}
                {isDirty && "*"}
              </span>
              <span>Updates</span> {saving && <LoadingOutlined />}
            </div>
          </div>
          {!simplified && <VersionSelector />}
          <div>
            <button
              onClick={onPublishToolBarClickAction}
              disabled={!!Object.values(errors).length}
              data-cy="fields-open-publish-modal"
              className={classNames({
                "rounded-btn": true,
                "rounded-btn--blue": true,
                "rounded-btn--disabled": !!Object.values(errors).length,
              })}
            >
              {isPublishing && <i className="rounded-btn__spinner" />}
              {!simplified ? `Publish` : `Submit`}
            </button>
            <Dropdown
              placement="topRight"
              overlay={
                <Menu>
                  <Menu.Item key="0">
                    {!simplified && (
                      <button
                        type="button"
                        onClick={() => {
                          setShowImportFieldsModal(true);
                        }}
                      >
                        <i className="mr-8 icon icon-copy icon--18" />
                        Import Fields
                      </button>
                    )}
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <a className="ant-dropdown-link pl-10" href="#">
                <span className="invst-header__actions-dropdown-btn icon icon-ellipsis" />
              </a>
            </Dropdown>
            {formServerUpdates.length > 0 && (
              <FormChanges changes={formServerUpdates} />
            )}
          </div>
        </div>
      </div>

      {showPublishModal && (
        <Modal
          title={`Publish ${name}`}
          visible={showPublishModal}
          onCancel={() => {
            setShowPublishModal(false);
          }}
          footer={
            <div className="main-modal__footer">
              <button
                onClick={() => {
                  setShowPublishModal(false);
                }}
                className="rounded-btn rounded-btn--grey mr-10 mb-15"
                type="button"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  publishFields(selectedFields);
                }}
                data-cy="publish-fields"
                disabled={isPublishing}
                className="rounded-btn rounded-btn--blue mb-15"
                type="button"
              >
                Submit
              </button>
              <Tag>{selectedFields.size}</Tag>
            </div>
          }
        >
          <p className="mb-25 ml-10">Do you want to publish these changes?</p>
          <div style={{ width: "100%", height: "25px", paddingRight: "16px" }}>
            <input
              type="checkbox"
              className="main-checkbox__input"
              checked={selectedFields.size !== 0}
              onChange={selectAllFields}
            />
            <label
              className="main-checkbox__label"
              onClick={selectAllFields}
              style={{ float: "right" }}
            >
              Select All
            </label>
          </div>
          <div
            className="mb-25"
            style={{ maxHeight: "500px", overflowY: "scroll" }}
          >
            {draftFields.map(draftField => {
              if (!draftField) return null;
              return (
                <ChangedFieldDiff
                  key={draftField.id}
                  draftField={draftField}
                  toggleField={toggleField}
                  isSelected={selectedFields.has(draftField)}
                />
              );
            })}
          </div>
        </Modal>
      )}
      {showImportFieldsModal && (
        <ImportFieldsModal
          visible={showImportFieldsModal}
          templateName={name as FieldSetType}
          ownerType={ownerType as FieldOwnerType}
          onCancel={() => {
            setShowImportFieldsModal(false);
          }}
        />
      )}
    </>
  );
}

import Markdown, { Components } from "react-markdown";
import RemarkGfm from "remark-gfm";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { CSSProperties } from "react";

function classes(...classNames: (string | undefined)[]) {
  return classNames.filter(Boolean).join(" ");
}

const components: Components = {
  a({ node, ...props }) {
    return <a {...props} target="_blank" rel="noreferrer" />;
  },
  table({ children, style, className }) {
    return (
      <Table
        size="small"
        className={classes(className, "markdown-table")}
        style={style as CSSProperties}
      >
        {children}
      </Table>
    );
  },
  thead({ children, style }) {
    return <TableHead style={style as CSSProperties}>{children}</TableHead>;
  },
  tbody({ children, style }) {
    return <TableBody style={style as CSSProperties}>{children}</TableBody>;
  },
  tr({ children, style }) {
    return <TableRow style={style as CSSProperties}>{children}</TableRow>;
  },
  td({ children, style }) {
    return <TableCell style={style as CSSProperties}>{children}</TableCell>;
  },
  th({ children, style }) {
    return <TableCell style={style as CSSProperties}>{children}</TableCell>;
  },
  li({ className, node, ...props }) {
    return <li {...props} className={classes(className, "markdown-li")} />;
  },
  ol({ className, node, ...props }) {
    return <ol {...props} className={classes(className, "markdown-ol")} />;
  },
  ul({ className, node, ...props }) {
    return <ul {...props} className={classes(className, "markdown-ul")} />;
  },
};

export function ChatMarkdown({ children }: { children?: string | null }) {
  return (
    <Markdown remarkPlugins={[RemarkGfm]} components={components}>
      {children}
    </Markdown>
  );
}

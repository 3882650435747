/* eslint-disable react/jsx-props-no-spreading */
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import { Input, Tooltip } from "antd";
import { isNaN } from "lodash";
import { useCallback, useEffect, useState } from "react";
import FieldChangeLog from "../FieldChangeLog";
import { useTemplateController } from "../FieldContext";
import { getFormRulesFromMetadata } from "../utils";
import { InputFieldProps } from "./InputFieldBase";
import { on } from "events";

function IntegerFieldInput({
  templateKey,
  metadata,
  templateOverride,
  formLookupKey,
  NotApplicableWrapper,
}: InputFieldProps): JSX.Element {
  const formRules = getFormRulesFromMetadata(metadata.value);
  console.log("formRules ->", formRules);
  const questionText = templateOverride?.questionText || metadata.questionText;
  const {
    field: { value, onChange, ...inputProps },
    fieldState: { error },
  } = useTemplateController({
    name: formLookupKey,
    ...(formRules && {
      rules: {
        ...formRules,
        validate: {
          shouldBeInteger: (val: any) =>
            val !== null ||
            !Number.isInteger(val) ||
            "should be an integer or null",
        },
      },
    }),
  });
  const [inputValue, setInputValue] = useState(
    (value as number | string | undefined) ?? ""
  );

  useEffect(() => {
    if (value !== inputValue) setInputValue(value as string | number);
  }, [value]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    console.log("HANDLE CHANGE ->", value);
    setInputValue(value);
    if (value === "") onChange(null);
    const num = Number(value);
    if (Number.isFinite(num)) {
      console.log("ON CHANGE ->", formLookupKey, num);
      onChange(num);
    }
  }

  const [focus, setFocus] = useState(false);
  const handleMouseEnter = useCallback(() => {
    setFocus(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setFocus(false);
  }, []);

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <NotApplicableWrapper {...{ metadata, templateKey }}>
        <>
          {!!questionText && (
            <div
              data-cy="template__textarea"
              data-test-id="template__textarea"
              className={"main-input__info"}
            >
              <div className="main-input__sublabel">{questionText}</div>
            </div>
          )}
          <div
            className="field-input-children-with-changelog"
            data-testid="integer-field"
          >
            <FieldChangeLog
              templateKey={templateKey}
              shouldDisplayBadge={focus}
            />
            <div className="integer-field">
              <Input
                value={inputValue}
                className="main-input-number"
                {...inputProps}
                onChange={handleChange}
                style={{ width: "70px", borderRadius: "3px" }}
                status={error && "error"}
                prefix={
                  error ? (
                    <Tooltip title={error.message}>
                      <ExclamationCircleOutlined />
                    </Tooltip>
                  ) : (
                    <span />
                  )
                }
              />
            </div>
          </div>
        </>
      </NotApplicableWrapper>
    </div>
  );
}

export default IntegerFieldInput;

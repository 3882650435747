import { UploadOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { gql } from "_graphql-types-frontend";
import {
  AddDocumentMutation,
  AddDocumentMutationVariables,
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables,
} from "_graphql-types-frontend/graphql";
import { notification, Upload } from "antd";
import { UploadFile, UploadFileStatus } from "antd/lib/upload/interface";
import { every as _every, forEach as _forEach, isEqual } from "lodash";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  BUSINESS_OBJECT_ENUM,
  DOCUMENT_ACCESS_LEVEL_ENUM,
  DOCUMENT_TYPE_ENUM,
} from "../../../utils/constants";
import { formatDate } from "../../../utils/date";
import Spinner from "../../Spinner";
import { TemplateContext } from "../Context";
import { PartialDocument, TemplateTypes } from "../types";
import { useTemplateController } from "./FieldContext";
import { InputFieldProps } from "./Inputs/InputFieldBase";

export const SAVE_DOCUMENT = gql(`
  mutation AddDocument($input: DocumentInput!) {
    addDocument(input: $input) {
      id
      name
      signedUrl
    }
  }
`);

export const DELETE_DOCUMENT = gql(`
  mutation DeleteDocument($id: Int!) {
    deleteDocument(id: $id)
  }
`);

function getBusinessObjectEnumIdFromOwnerType(ownerType: TemplateTypes) {
  switch (ownerType) {
    case "investment":
      return BUSINESS_OBJECT_ENUM.investment;
    case "firm":
      return BUSINESS_OBJECT_ENUM.firm;
    case "company":
      return BUSINESS_OBJECT_ENUM.company;
  }
}

const replaceGeneratedFileId = (generatedId: string) => {
  /* 
    this is a little hacky
    antd internally uses Date.now to generate the uid but it does not respect the jest fake system time 
    which creates a problem with mocks
  */
  const PREFIX = "rc-upload-";
  if (!generatedId.includes(PREFIX)) return generatedId;
  const [_timestamp, index] = generatedId.split(PREFIX).join("").split("-");
  return `${PREFIX}${Date.now()}-${index}`;
};

type FileInputProps = {
  supportedFileTypes?: string[];
  documentTypeEnumId?: number;
};

const Dragger = Upload.Dragger;

function isFileList(file: File | FileList): file is FileList {
  return "length" in file;
}

function getFileListFromDocuments(
  documents?: PartialDocument[],
  status: UploadFileStatus = "done"
): UploadFile[] {
  if (!documents) return [];
  return documents.flatMap(({ document: doc }) =>
    doc ? [formatDocumentForAntd(doc, status)] : []
  );
}

function formatDocumentForAntd(
  document: Omit<NonNullable<PartialDocument["document"]>, "date">,
  status: UploadFileStatus = "done"
): UploadFile {
  return {
    uid: String(document.id),
    name: document.name,
    status,
    url: document.signedUrl ?? undefined,
  };
}

function isDragEvent(
  eventOrFile: File | React.DragEvent<HTMLDivElement>
): eventOrFile is React.DragEvent<HTMLDivElement> {
  return "type" in eventOrFile && eventOrFile.type === "drop";
}

const DEFAULT_SUPPORTED_FILES = ["image/png", "image/jpeg"];

const FileInput = memo(function FileInput({
  templateKey,
  formLookupKey,
  documentTypeEnumId = DOCUMENT_TYPE_ENUM.supplementalDiligenceVisuals,
  supportedFileTypes = DEFAULT_SUPPORTED_FILES,
}: Omit<InputFieldProps, "NotApplicableWrapper"> &
  FileInputProps): JSX.Element {
  const [_saveDocument, { loading }] = useMutation<
    AddDocumentMutation,
    AddDocumentMutationVariables
  >(SAVE_DOCUMENT, {
    onCompleted: data => handleUploadCompletion(data),
    onError: error => {
      console.error(error);
      notification.error({
        message: "Failed to upload file.",
        key: "file_upload_failed",
      });
    },
  });
  const [_deleteDocument, { error }] = useMutation<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >(DELETE_DOCUMENT, {
    onCompleted: data => handleDeleteCompletion(data),
    onError: error => {
      console.error(error);
      if (error.message.includes("pemission to delete")) {
        notification.error({
          message: "Permission to delete file denied.",
          key: "file_delete_permission_denied",
        });
      } else {
        notification.error({
          message: "Failed to delete file.",
          key: "file_delete_failed",
        });
      }
    },
  });
  const [_, fieldKey] = templateKey.split(".");
  const { ownerId, ownerType, readFieldFromCache } =
    useContext(TemplateContext);
  const { field } = readFieldFromCache(templateKey);
  const { field: draftField } = readFieldFromCache(templateKey);
  let mostRecentField = field;
  if (
    (!field && draftField) ||
    (field && draftField && draftField.modifyDate > field?.modifyDate)
  ) {
    mostRecentField = draftField;
  }

  const {
    field: { onChange, value },
  } = useTemplateController({
    name: formLookupKey,
  });

  const [inputValue, setInputValue] = useState<UploadFile[]>(
    getFileListFromDocuments(mostRecentField?.documents)
  );

  useEffect(() => {
    if (!isEqual(value, inputValue))
      setInputValue(getFileListFromDocuments(mostRecentField?.documents));
  }, [value]);

  const handleUploadCompletion = useCallback(
    (data: AddDocumentMutation) => {
      notification.success({
        message: "File uploaded successfully",
        key: "file_upload_success",
      });
      setInputValue(inputValue.concat(formatDocumentForAntd(data.addDocument)));
      onChange([...(value ?? [])].concat([data.addDocument.id]));
    },
    [setInputValue, onChange]
  );

  const validateFile = useCallback(
    (file: File | FileList) => {
      let isAllowed: boolean;
      if (isFileList(file)) {
        isAllowed = _every(file, f => supportedFileTypes.includes(f.type));
      } else {
        isAllowed = supportedFileTypes.includes(file.type);
      }
      if (!isAllowed)
        notification.error({
          message: `File is not supported. Must be of type [${supportedFileTypes}]`,
          key: "file_type_unsupported",
        });
      return isAllowed;
    },
    [supportedFileTypes]
  );

  const handleDeleteCompletion = useCallback(
    (data: DeleteDocumentMutation) => {
      notification.success({
        message: "File deleted successfully",
        key: "file_delete_success",
      });
      setInputValue(
        inputValue.filter(val => val.uid !== String(data.deleteDocument))
      );
      onChange([...(value ?? [])].filter(id => id !== data.deleteDocument));
    },
    [setInputValue, onChange]
  );

  const saveDocument = useCallback(
    (file: File) =>
      _saveDocument({
        variables: {
          input: {
            businessObjectEnumId:
              getBusinessObjectEnumIdFromOwnerType(ownerType),
            file,
            date: formatDate(new Date()),
            accessLevel: DOCUMENT_ACCESS_LEVEL_ENUM.PUBLIC,
            documentTypeEnumId,
            ...(ownerType === "firm" && { firmId: Number(ownerId) }),
            ...(ownerType === "investment" && {
              investmentId: Number(ownerId),
            }),
            ...(ownerType === "company" && { companyId: Number(ownerId) }),
          },
        },
      }),
    [_saveDocument]
  );

  const handleChange = useCallback(
    (eventOrFile: File | React.DragEvent<HTMLDivElement>) => {
      if (isDragEvent(eventOrFile)) {
        const fileList = eventOrFile.dataTransfer.files;
        // beforeUpload does not run on drag and drop when accept prop is used https://github.com/ant-design/ant-design/issues/36318
        const isAllowed = validateFile(fileList);
        if (isAllowed) {
          _forEach(fileList, file => saveDocument(file));
        }
      } else {
        saveDocument(eventOrFile);
      }
    },
    [saveDocument]
  );

  const deleteDocument = useCallback(
    (file: UploadFile) => {
      _deleteDocument({
        variables: {
          id: Number(file.uid),
        },
      });
    },
    [_deleteDocument]
  );

  const beforeUpload = useCallback(() => {
    (file: File & { uid: string }) => {
      file.uid = replaceGeneratedFileId(file.uid);
      const isAllowed = validateFile(file);
      return isAllowed || Upload.LIST_IGNORE;
    };
  }, [replaceGeneratedFileId, validateFile]);

  const customRequest = useCallback(
    ({ onSuccess, file }: any) => {
      handleChange(file);
      onSuccess("ok");
    },
    [handleChange]
  );

  const defaultFileList = useMemo(() => {
    return getFileListFromDocuments(mostRecentField?.documents);
  }, [mostRecentField?.documents]);

  const children = useMemo(() => {
    return (
      <Dragger
        beforeUpload={beforeUpload}
        data-testid="fields__fileInput-input"
        multiple={true}
        maxCount={3}
        accept={supportedFileTypes.join(",")}
        name={fieldKey}
        defaultFileList={defaultFileList}
        fileList={inputValue}
        customRequest={customRequest}
        onRemove={deleteDocument}
      >
        <span className="ant-upload-drag-icon">
          {loading ? <Spinner /> : <UploadOutlined />}
        </span>
        <p className="ant-upload-text">
          Click or drag image to this area to upload
        </p>
      </Dragger>
    );
  }, [
    loading,
    beforeUpload,
    supportedFileTypes,
    inputValue,
    defaultFileList,
    fieldKey,
    deleteDocument,
    customRequest,
  ]);

  return (
    <div data-testid="fields__fileInput" className="fields-input__file">
      {children}
    </div>
  );
});

export default FileInput;

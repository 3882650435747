import { FileExcelOutlined, LoadingOutlined } from "@ant-design/icons";
import { ApolloClient, useSubscription } from "@apollo/client";
import { captureException } from "@sentry/react";
import { Button, Dropdown, Modal, Tooltip } from "antd";
import { useCallback, useRef, useState } from "react";
import {
  ASYNC_DOCUMENT_PROCESS,
  DOCUMENT_PROCESS_MESSAGE_SUBSCRIPTION,
} from "./graphql";

type Writeable<T> = { -readonly [P in keyof T]: T[P] };

function writeable<const T>(value: T) {
  return value as Writeable<T>;
}

const ITEMS = writeable([
  { label: "Extract Exposure Data", key: "ExposureExtraction" },
  { label: "Extract Opera Data", key: "OperaExtraction" },
  { label: "Extract Position Data", key: "UnderlyingPositions" },
  { label: "Extract Tables", key: "TableExtraction" },
  { label: "Extract Time Series", key: "TimeSeries" },
]);

const randomId = () =>
  `${Date.now().toString(36)}-${Math.random().toString(36).slice(2)}`;

export function DocumentProcessButton({
  documentId,
  apolloClient,
  markdownComponent: MarkdownComponent,
}: {
  documentId: number;
  apolloClient: ApolloClient<any>;
  markdownComponent: React.ComponentType<{ children?: string | null }>;
}) {
  const [sessionId, setSessionId] = useState(randomId());
  const request = useRef<[{ requestId: string; processName: string }?]>([]);
  const [loading, setLoading] = useState(false);
  const [processResult, setProcessResult] = useState<{
    message: string;
    label?: string;
  }>();
  useSubscription(DOCUMENT_PROCESS_MESSAGE_SUBSCRIPTION, {
    variables: {
      sessionId,
    },
    onData: ({ data: { data } }) => {
      console.log({ data });
      if (!data) return;
      if (!request.current[0]) return;
      const { requestId, processName } = request.current[0];
      if (data.chatMessages.requestId !== requestId) return;

      request.current[0] = void 0;
      setLoading(false);
      let { message } = data.chatMessages.messageStream;

      if (data.chatMessages.error) {
        message ||= "An error occurred while processing the document.";
        setProcessResult({ message });
        return;
      }

      message ||= "No data was extracted!";
      const iitem = ITEMS.findIndex(item => item.key === processName);
      const label = iitem >= 0 ? ITEMS[iitem].label : "";
      setProcessResult({ message, label });
    },
  });

  const documentProcess = useCallback(
    async (processName: string) => {
      const requestId = randomId();
      request.current[0] = { requestId, processName };
      setLoading(true);
      setProcessResult(void 0);
      try {
        await apolloClient.query({
          query: ASYNC_DOCUMENT_PROCESS,
          variables: { documentId, processName, sessionId, requestId },
          fetchPolicy: "no-cache",
        });
        console.log("documentId ->", documentId);
        const startTime = Date.now();
        const timeout = startTime + 1000 * 60 * 5; // 5 minutes

        for (;;) {
          await new Promise(resolve => setTimeout(resolve, 5000));
          if (request.current[0]?.requestId !== requestId) {
            break;
          }
          if (Date.now() > timeout) {
            console.log("Timeout!");
            captureException(
              new Error(
                `Timed out waiting for async lambda ${processName} to finish`
              )
            );
            setProcessResult({
              message:
                "The document processing has taken too long and has been abandonned.",
            });
          }
        }
      } catch (e: any) {
        console.error(e);
        setProcessResult({
          message: "An error occurred while processing the document.",
        });
      }
    },
    [documentId]
  );

  return (
    <>
      <Tooltip title="Document Processing">
        <Dropdown
          menu={{
            items: ITEMS,
            onClick: ({ key }) => documentProcess(key),
          }}
        >
          <Button
            type="primary"
            shape="circle"
            icon={loading ? <LoadingOutlined /> : <FileExcelOutlined />}
            size="small"
            onClick={e => e.preventDefault()}
            style={{ fontSize: 14, margin: "3px" }}
          />
        </Dropdown>
      </Tooltip>
      <Modal
        title="Document Processing Result"
        open={!!processResult}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={_ => setProcessResult(void 0)}
          >
            OK
          </Button>,
        ]}
        maskClosable={false}
        onCancel={_ => setProcessResult(void 0)}
        onOk={_ => setProcessResult(void 0)}
      >
        <MarkdownComponent>{processResult?.message}</MarkdownComponent>
      </Modal>
    </>
  );
}

/* eslint-disable no-shadow */
/* eslint-disable max-lines-per-function */
import { useApolloClient, useQuery } from "@apollo/client";
import { get } from "lodash";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as types from "_graphql-types-frontend/graphql";

import { useCurrentUser } from "../../../utils/hooks";

import { dynamicgql } from "../../../utils/graphql";

import uuid from "../../../utils/uuid";

import {
  ContentItem,
  Field,
  FieldInput,
  FieldState,
  Metadata,
  TemplateContextInterface,
  TemplateParams,
} from "../types";
import { getTemplateConfigForOwner } from "./config";

export const NO_QUERY = dynamicgql`
  query noQuery {
    nothing
  }
`;

const nullMetadata = {
  displayType: "none",
  displayName: "none",
  value: {
    returnTypeName: "none",
    propertyKey: "none",
    isScalar: false,
    nullable: false,
    arrayDepth: 0,
    props: [],
  },
};

const nullFieldFromCache = {
  field: null,
  metadata: nullMetadata,
};

function ownerTypeFromTemplateKey(templateKey: string): types.FieldOwnerType {
  if (templateKey.toLowerCase().startsWith("investment")) {
    return types.FieldOwnerType.Investment;
  }
  if (templateKey.toLowerCase().startsWith("firm")) {
    return types.FieldOwnerType.Firm;
  }
  if (templateKey.toLowerCase().startsWith("company")) {
    return types.FieldOwnerType.Company;
  }
  throw new Error(`Invalid ownerType from TemplateKey: ${templateKey}`);
}

const defaultGetModifyDate = () => new Date();

export function useTemplate({
  ownerId,
  name,
  ownerType,
  editMode,
  getModifyDate = defaultGetModifyDate,
  simplified = false,
  onIsCompleted,
  onPublish,
}: TemplateParams): TemplateContextInterface {
  const {
    templateQuery,
    getTemplateDataQuery,
    getTemplateData,
    getFieldsData,
    updateTemplateQuery,
    saveFieldsQuery,
    deleteFieldsQuery,
    getEntityOwnerIds,
  } = useMemo(() => {
    return getTemplateConfigForOwner(ownerType);
  }, [ownerType, getTemplateConfigForOwner]);

  const [templateVersion, changeTemplateVersion] = useState<string | undefined>(
    undefined
  );

  const [saving, setSaving] = useState<boolean>(false);

  const [persistingFields, setPersistingFields] = useState<{
    [key: string]: string[] | undefined;
  }>({});

  const isPersistingField = useCallback(
    (field: Field): boolean => {
      if (!field) return false;
      const requestList =
        persistingFields[
          `${field.ownerId}::${field?.ownerType}::${field.fieldKey}`
        ];
      if (!requestList) return false;

      return requestList.length > 0;
    },
    [persistingFields]
  );

  const markFieldsPersisting = useCallback(
    (fields: Field[], requestId: string) => {
      setPersistingFields(prev => {
        for (const field of fields) {
          if (!field) continue;
          if (
            !prev[`${field.ownerId}::${field?.ownerType}::${field.fieldKey}`]
          ) {
            prev[`${field.ownerId}::${field?.ownerType}::${field.fieldKey}`] =
              [];
          }

          prev[
            `${field.ownerId}::${field?.ownerType}::${field.fieldKey}`
          ]?.push(requestId);
        }
        return prev;
      });
    },
    [setPersistingFields]
  );

  const markFieldPersisted = useCallback(
    (fields: Field[], requestId: string) => {
      setPersistingFields(prev => {
        for (const field of fields) {
          if (!field) continue;
          if (
            !prev[`${field.ownerId}::${field?.ownerType}::${field.fieldKey}`]
          ) {
            prev[`${field.ownerId}::${field?.ownerType}::${field.fieldKey}`] =
              [];
          }
          prev[`${field.ownerId}::${field?.ownerType}::${field.fieldKey}`] =
            prev[
              `${field.ownerId}::${field?.ownerType}::${field.fieldKey}`
            ]?.filter(v => v !== requestId);
        }
        return prev;
      });
    },
    [setPersistingFields]
  );

  const currentUser = useCurrentUser();

  const { data, error, loading, refetch } = useQuery(templateQuery, {
    variables: {
      id: ownerId,
      name,
      state: null, // not used this query is just to fetch template.
      version: templateVersion,
    },
    skip: !ownerId,
  });

  const entityOwnerIds = useMemo(() => getEntityOwnerIds(data), [data]);

  const templateData = useMemo(() => {
    return data && getTemplateData(data);
  }, [data]);

  const fieldSet = templateData?.fieldSet as types.GenericFieldSet | undefined;
  const content = fieldSet?.content as ContentItem[] | undefined;

  const hideImageUpload = fieldSet?.config?.hide_image_upload || false;

  const dataQuery = useMemo(
    () => getTemplateDataQuery(name, data),
    [name, data]
  );

  const publishedFieldData = useQuery(dataQuery.query, {
    variables: dataQuery.variables({ state: currentUser.writePublishState }),
    fetchPolicy: "cache-and-network",
    // https://github.com/apollographql/apollo-client/issues/6819#issuecomment-671643538
    nextFetchPolicy: "cache-first",
    pollInterval: 60000,
    skip: dataQuery.query === NO_QUERY,
  });

  const managerFieldData = useQuery(dataQuery.query, {
    variables: dataQuery.variables({ state: types.FieldState.Mgr }),
    fetchPolicy: "cache-and-network",
    // https://github.com/apollographql/apollo-client/issues/6819#issuecomment-671643538
    nextFetchPolicy: "cache-first",
    pollInterval: 60000,
    skip: dataQuery.query === NO_QUERY,
  });

  const editFieldData = useQuery(dataQuery.query, {
    variables: dataQuery.variables({ state: currentUser.writeDraftState }),
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    pollInterval: 60000,
    skip: dataQuery.query === NO_QUERY || !editMode,
  });

  const client = useApolloClient();

  const internalError =
    error ??
    editFieldData?.error ??
    managerFieldData?.error ??
    publishedFieldData?.error;

  if (error) {
    console.log("ERROR!!");
  }

  const internalLoading = !!(
    loading ||
    managerFieldData?.loading ||
    editFieldData?.loading ||
    publishedFieldData?.loading
  );

  const readFieldFromCache = useCallback(
    (
      key: string,
      state?: types.FieldState.Mgr | null
    ): {
      field: Field;
      metadata: Metadata;
    } => {
      if (!templateData || !publishedFieldData.data || !managerFieldData.data)
        return nullFieldFromCache;

      const [fieldOwnerType, fieldKey] = key.split(".");
      const metadata = get(templateData.fieldMeta, [fieldOwnerType, fieldKey]);

      let field;
      if (state !== undefined) {
        if (state === null) {
          field = get(publishedFieldData.data, [
            fieldOwnerType,
            "fields",
            fieldKey,
          ]);
        }
        if (state === types.FieldState.Mgr) {
          field = get(managerFieldData.data, [
            fieldOwnerType,
            "fields",
            fieldKey,
          ]);
        }
      } else {
        if (currentUser.flags.rcgDataAccess) {
          field = get(publishedFieldData.data, [
            fieldOwnerType,
            "fields",
            fieldKey,
          ]);
        }
        if (!field) {
          field = get(managerFieldData.data, [
            fieldOwnerType,
            "fields",
            fieldKey,
          ]);
        }
      }

      return {
        metadata,
        field,
      };
    },
    [publishedFieldData, managerFieldData, templateData]
  );

  const readDraftFieldFromCache = useCallback(
    (key: string): { field: Field; metadata: Metadata } => {
      if (!templateData || !editFieldData.data) return nullFieldFromCache;

      const [fieldOwnerType, fieldKey] = key.split(".");
      const metadata = get(templateData.fieldMeta, [
        fieldOwnerType,
        "fields",
        fieldKey,
      ]);

      const field = get(editFieldData.data, [
        fieldOwnerType,
        "fields",
        fieldKey,
      ]);

      return {
        metadata,
        field,
      };
    },
    [editFieldData, templateData]
  );

  const saveFields = useCallback(
    (
      inputData: Record<string, Record<string, FieldInput>>,
      state: FieldState | null
    ) => {
      const query = saveFieldsQuery(inputData, data);

      if (!query) return Promise.reject(new Error("no query"));

      const requestId = uuid();
      const inputFields = Object.values(inputData)
        .map(a => Object.values(a))
        .flat()
        .flat() as Field[];
      markFieldsPersisting(inputFields, requestId);
      setSaving(true);

      return client
        .mutate({
          mutation: query.query,
          variables: query.variables({ state }),
        })
        .finally(() => {
          setTimeout(() => {
            markFieldPersisted(inputFields, requestId);
          }, 30000);
          setSaving(false);
        });
    },
    [saveFieldsQuery, data]
  );

  const deleteFields = useCallback(
    (inputData: any, state: FieldState | null) => {
      const query = deleteFieldsQuery(inputData, data);

      if (!query) return Promise.reject(new Error("no query"));

      return client.mutate({
        mutation: query.query,
        variables: query.variables({ state }),
      });
    },
    [saveFieldsQuery, data]
  );

  const updateTemplateVersion = useCallback(
    (state: types.FieldState.Draft | types.FieldState.MgrDraft | null) => {
      if (!data) return Promise.resolve(null); // this occurs during defaulting.
      if (!currentUser.email || !data)
        return Promise.reject(new Error("no current user or data"));

      const templateQuery = updateTemplateQuery(
        currentUser.email,
        name,
        templateVersion || data?.[ownerType]?.fieldData?.version,
        data
      );

      if (!templateQuery) return Promise.reject();

      return client.mutate({
        mutation: templateQuery.query,
        variables: templateQuery.variables({
          state,
        }),
      });
    },
    [client, name, templateVersion, currentUser.email, data]
  );

  useEffect(() => {
    if (templateVersion) {
      updateTemplateVersion(currentUser.writeDraftState);
    }
  }, [templateVersion, currentUser]);

  const draftData: Record<string, Record<string, Field>> | null =
    useMemo(() => {
      if (!editFieldData.data) return null;
      return getFieldsData(editFieldData.data);
    }, [editFieldData.data]);

  const publishedData: Record<string, Record<string, Field>> | null =
    useMemo(() => {
      if (!publishedFieldData?.data) return null;
      return getFieldsData(publishedFieldData.data);
    }, [publishedFieldData.data]);

  useEffect(() => {
    if (
      onIsCompleted &&
      Object.values({
        ...publishedData?.investment,
        ...publishedData?.company,
      }).length > 0 &&
      Object.values({
        ...publishedData?.investment,
        ...publishedData?.company,
      }).filter(v => v === null).length === 0
    ) {
      onIsCompleted();
    }
  }, [publishedData, onIsCompleted]);

  const initialTemplateDate = useMemo(() => {
    return getModifyDate();
  }, [getModifyDate]);

  const formDataValues = useMemo(() => {
    if (!publishedData || !draftData || !currentUser.email) return null;

    const formData: Record<string, Record<string, Field>> = {};
    for (const [entityKey, publishedValues] of Object.entries(publishedData)) {
      const draftValues = draftData[entityKey];
      const ownerId = editFieldData.data[entityKey].id;
      const ownerType = ownerTypeFromTemplateKey(entityKey);
      if (!ownerId) {
        console.warn("No ownerId for templateKey! Empty Form used", entityKey);
        continue;
      }
      for (const [fieldKey, publishValue] of Object.entries(publishedValues)) {
        const draftValue = draftValues[fieldKey];

        if (
          [
            "id",
            "__typename",
            "state",
            ...(entityOwnerIds
              ? Object.values(entityOwnerIds).map(({ fieldKey }) => fieldKey)
              : []),
          ].includes(fieldKey)
        ) {
          continue;
        }

        formData[entityKey] = formData[entityKey] || {};

        //This creates the field if it doesn't exist in the draft.
        formData[entityKey][fieldKey] = {
          value: undefined,
          createDate: new Date(),
          createUser: currentUser.email as string,
          id: uuid(),
          ...(draftValue ?? publishValue),
          ownerId,
          fieldKey,
          ownerType,
          state: currentUser.writeDraftState,
          modifyDate:
            draftValue?.modifyDate || publishValue?.modifyDate
              ? new Date(draftValue?.modifyDate || publishValue?.modifyDate)
              : initialTemplateDate,
          modifyUser:
            draftValue?.modifyUser ||
            publishValue?.modifyUser ||
            currentUser.email,
          documents: draftValue?.documents || publishValue?.documents || [],
          documentIds:
            draftValue?.documentIds || publishValue?.documentIds || [],
        };
      }
    }

    return formData;
  }, [publishedData, draftData, currentUser, internalLoading, entityOwnerIds]);

  const getMetadata = useCallback(
    (fieldKey: string) => get(templateData, `fieldMeta.${fieldKey}`),
    [templateData]
  );

  const template = useMemo(
    () => ({
      getMetadata,
      name,
      displayName: fieldSet?.display_name ?? "",
      hideImageUpload,
      content: content ?? [],
      error: internalError,
      loading: internalLoading,
      saving,
      setSaving,
      ownerType,
      entityOwnerIds,
      ownerId,
      editMode,
      defaultVersion: data?.[ownerType]?.fieldData?.version,
      availableVersions: data?.[ownerType]?.templateOptions || [],
      templateVersion,
      changeTemplateVersion,
      updateTemplateVersion,
      readFieldFromCache,
      readDraftFieldFromCache,
      draftData,
      publishedData,
      formDataValues,
      saveFields,
      deleteFields,
      getModifyDate,
      simplified,
      onPublish,
      onIsCompleted,
      isPersistingField,
    }),
    [
      getMetadata,
      name,
      saveFields,
      deleteFields,
      content,
      internalError,
      loading,
      saving,
      setSaving,
      entityOwnerIds,
      ownerType,
      ownerId,
      editMode,
      data?.investment?.fieldData?.version,
      data?.investment?.templateOptions,
      templateVersion,
      updateTemplateVersion,
      readFieldFromCache,
      readDraftFieldFromCache,
      draftData,
      publishedData,
      internalLoading,
      getModifyDate,
      simplified,
      onPublish,
      onIsCompleted,
      isPersistingField,
    ]
  );

  return template;
}

export const TemplateContext = createContext<TemplateContextInterface>({
  displayName: "",
  name: "Empty Template",
  hideImageUpload: false,
  content: [],
  error: undefined,
  loading: false,
  saving: false,
  setSaving: (state: boolean) => {},
  ownerType: types.FieldOwnerType.Investment,
  ownerId: "",
  entityOwnerIds: {},
  editMode: false,
  defaultVersion: undefined,
  availableVersions: [],
  templateVersion: undefined,
  changeTemplateVersion: () => null,
  updateTemplateVersion: async _ => null,
  formDataValues: null,
  readFieldFromCache: () => nullFieldFromCache,
  readDraftFieldFromCache: () => nullFieldFromCache,
  publishedData: null,
  draftData: null,
  saveFields: async () => null,
  deleteFields: async () => null,
  getMetadata: () => nullMetadata,
  getModifyDate: defaultGetModifyDate,
  simplified: false,
  onPublish: () => {},
  onIsCompleted: () => {},
  isPersistingField: () => false,
});
